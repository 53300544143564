export default [
  {
    path: "/",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
    meta: {
      isLogin: true,
    },
  },
  {
    path: "/knowledgeBase",
    name: "knowledgeBase",
    component: () => import("@/views/knowledgeBase/index.vue"),
  },
  {
    path: "/soilScreen",
    name: "soilScreen",
    component: () => import("@/views/analyse/soilScreen.vue"),
    meta: {
      isLogin: true,
      title: "大屏",
    },
  },
  {
    path: "/weatherScreen",
    name: "weatherScreen",
    component: () => import("@/views/analyse/weatherScreen.vue"),
    meta: {
      isLogin: true,
      title: "大屏",
    },
  },
  {
    path: "/fj",
    name: "fj",
    component: () => import("@/views/login/fj.vue"),
    meta: {
      isLogin: true,
    },
  },

  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/traceability",
    name: "traceability",
    redirect: "/traceability/lot",
    component: () => import("@/views/traceability.vue"),
    children: [
      {
        path: "/traceability/lot",
        name: "lot",
        component: () => import("@/views/traceability/lot.vue"),
        meta: {
          title: "lot",
        },
      },
      {
        path: "/traceability/barcode",
        name: "barCode",
        component: () => import("@/views/traceability/barCode.vue"),
        meta: {
          title: "barCode",
        },
      },
      {
        path: "/traceability/info",
        name: "info",
        component: () => import("@/views/traceability/info.vue"),
        meta: {
          title: "info",
        },
      },
      {
        path: "/traceability/infoinput",
        name: "infoInput",
        component: () => import("@/views/traceability/infoInput.vue"),
        meta: {
          title: "infoinput",
        },
      },
      {
        path: "/traceability/stat",
        name: "stat",
        component: () => import("@/views/traceability/stat.vue"),
        meta: {
          title: "stat",
        },
      },
      {
        path: "/traceability/uplink",
        name: "upLink",
        component: () => import("@/views/traceability/upLink.vue"),
        meta: {
          title: "uplink",
        },
      },
    ],
  },
  {
    path: "/land",
    name: "land",
    component: () => import("@/views/land/land.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/vr",
    name: "vr",
    component: () => import("@/views/vr.vue"),
  },
  {
    path: "/farmadmin",
    name: "farmAdmin",
    component: () => import("@/views/farmAdmin.vue"),
  },
  {
    path: "/linkman",
    name: "linkman",
    component: () => import("@/views/linkman.vue"),
  },
  {
    path: "/configure",
    name: "configure",
    redirect: "/configure/farm",
    component: () => import("@/views/configure/index.vue"),
    children: [
      {
        path: "/configure/farm",
        name: "farm",
        component: () => import("@/views/configure/farm.vue"),
        meta: {
          title: "farm",
        },
      },
      {
        path: "/configure/cropType",
        name: "cropType",
        component: () => import("@/views/configure/cropType.vue"),
        meta: {
          title: "cropType",
        },
      },
      {
        path: "/configure/assetType",
        name: "assetType",
        component: () => import("@/views/configure/assetType.vue"),
        meta: {
          title: "assetType",
        },
      },
      {
        path: "/configure/processTemplate",
        name: "processTemplate",
        component: () => import("@/views/configure/processTemplate.vue"),
        meta: {
          title: "processTemplate",
        },
      },
      {
        path: "/configure/process",
        name: "process",
        component: () => import("@/views/configure/process.vue"),
        meta: {
          title: "process",
        },
      },
      {
        path: "/configure/datum",
        name: "datum",
        component: () => import("@/views/configure/datum.vue"),
        meta: {
          title: "datum",
        },
      },
      {
        path: "/configure/password",
        name: "password",
        component: () => import("@/views/configure/password.vue"),
        meta: {
          title: "password",
        },
      },
      {
        path: "/configure/log",
        name: "log",
        component: () => import("@/views/configure/log.vue"),
        meta: {
          title: "log",
        },
      },

      {
        path: "/configure/statement",
        name: "statement",
        component: () => import("@/views/configure/statement.vue"),
        meta: {
          title: "statement",
        },
      },
    ],
  },
  {
    path: "/alter",
    name: "alter",
    component: () => import("@/views/alter.vue"),
  },

  {
    path: "/means",
    name: "means",
    component: () => import("@/views/means.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/crops",
    name: "crops",
    component: () => import("@/views/crops.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/personnel",
    name: "personnel",
    component: () => import("@/views/personnel.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/news.vue"),
  },

  {
    path: "/know",
    name: "knowledge",
    component: () => import("@/views/knowledge.vue"),
  },
  {
    path: "/dialogue",
    name: "dialogue",
    component: () => import("@/views/dialogue/index.vue"),
  },

  {
    path: "/task",
    name: "task",
    component: () => import("@/views/task.vue"),
  },
  {
    path: "/equipment",
    name: "equipment",
    redirect: "/equipment/gather",
    component: () => import("@/views/equipment.vue"),
    children: [
      {
        path: "/equipment/meteorological",
        name: "meteorological",
        component: () => import("@/views/equipment/meteorological.vue"),
      },
      {
        path: "/equipment/chemical",
        name: "chemical",
        component: () => import("@/views/equipment/chemical.vue"),
      },

      {
        path: "/equipment/gather",
        name: "gather",
        component: () => import("@/views/equipment/gather.vue"),
      },
    ],
  },
  {
    path: "/farmShow/index",
    name: "farmIndex",
    redirect: "/farmShow/show",
    component: () => import("@/views/farmShow/index.vue"),
    children: [
      {
        path: "/farmShow/show",
        name: "show",
        redirect: "/farmShow/show/list",
        component: () => import("@/views/farmShow/show.vue"),
        children: [
          {
            path: "/farmShow/show/list",
            name: "showList",
            component: () => import("@/views/farmShow/list.vue"),
          },
          {
            path: "/farmShow/show/edit",
            name: "showEdit",
            component: () => import("@/views/farmShow/edit.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/exception/403",
    name: "403",
    component: () => import("@/views/exception/403.vue"),
    meta: {
      title: "403",
      isLogin: true,
    },
  },
  {
    path: "/exception/404",
    name: "404",
    component: () => import("@/views/exception/404.vue"),
    meta: {
      title: "404",
      isLogin: true,
    },
  },
  {
    path: "/exception/500",
    name: "500",
    component: () => import("@/views/exception/500.vue"),
    meta: {
      title: "500",
      isLogin: true,
    },
  },
  {
    path: "/exception/600",
    name: "600",
    component: () => import("@/views/exception/routeError.vue"),
    meta: {
      title: "路由配置错误",
      isLogin: true,
    },
  },
  {
    path: "/bigWeb",
    name: "bigWeb",
    component: () => import("@/views/fullScreen/bigWeb.vue"),
    meta: {
      title: "图表",
      isLogin: true,
    },
  },
  {
    path: "/jsweb",
    name: "jsweb",
    component: () => import("@/views/fullScreen/jsweb.vue"),
    meta: {
      title: "图表",
      isLogin: true,
    },
  },
  {
    path: "/ljym",
    name: "ljymWeb",
    component: () => import("@/views/fullScreen/ljymWeb.vue"),
    meta: {
      title: "图表",
      isLogin: true,
    },
  },
  {
    path: "/jfweb",
    name: "jfWeb",
    component: () => import("@/views/fullScreen/jfWeb.vue"),
    meta: {
      title: "图表",
      isLogin: true,
    },
  },
  {
    path: "/noteBook",
    name: "noteBook",
    component: () => import("@/views/noteBook.vue"),
    meta: {
      title: "产品手册",
    },
  },
  {
    path: "/AI",
    name: "AI",
    component: () => import("@/views/ai/index.vue"),
    meta: {
      title: "AI",
    },
    children: [],
  },
  {
    path: "/administer",
    name: "administer",
    component: () => import("@/views/ai/administer.vue"),
    meta: {
      title: "数字治理",
    },
  },
  {
    path: "/ai/lands",
    name: "aiLands",
    component: () => import("@/views/ai/lands.vue"),
  },
];
