// 全局过滤器
import Vue from "vue";
Vue.filter("NumberFormat", function (value) {
  if (!value) {
    return "0";
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
  return intPartFormat;
});
Vue.filter("Date", (dateStr) => {
  const date = new Date(dateStr * 1000);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  return year + "-" + month + "-" + day;
});
Vue.filter("dateNow", (dateStr) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  return year + "年" + month + "月" + day + "日";
});
Vue.filter("yearDate", (dateStr) => {
  const date = new Date(dateStr * 1000);
  const year = date.getFullYear();
  return year;
});
