const getDefaultState = () => {
  return {
    token: localStorage.getItem("darsingToken") || "",
    exp: localStorage.getItem("exp") || "",
    farmID: +localStorage.getItem("farmId") || "",
    equipmentName: sessionStorage.getItem("equipmentName") || "",

    userInfo: JSON.parse(localStorage.getItem("userInfoObj")) || {},
    msgCount: null,
    nav: 0,
    taskCount: null,
    farmInfo: JSON.parse(localStorage.getItem("farmInfo")) || {},
    farmShow: {},
    web_menu: JSON.parse(localStorage.getItem("web_menu")) || [
      {
        name: "首页",
        link: "index",
      },
      {
        name: "溯源云",
        link: "traceability",
      },
      {
        name: "设备云",
        link: "gather",
        line: 1,
      },
      {
        name: "知识云",
        link: "knowledge",
        line: 1,
      },
      {
        name: "农事云",
        link: "task",
        line: 1,
      },
      {
        name: "土地管理",
        link: "land",
      },
      {
        name: "产品管理",
        link: "crops",
      },
      {
        name: "农资管理",
        link: "means",
      },

      {
        name: "员工管理",
        link: "personnel",
      },
      {
        name: "供应商管理",
        link: "linkman",
      },
      {
        name: "全景VR",
        link: "vr",
      },
      {
        name: "系统配置",
        link: "farm",
      },
    ],
  };
};
const state = getDefaultState();

const mutations = {
  SET_NAV: (state, { nav }) => {
    state.nav = nav;
  },
  SET_EQUIPMENT_Name: (state, { icon }) => {
    state.equipmentName = icon;
  },
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },

  SET_MSG_COUNT: (state, { msgCount }) => {
    state.msgCount = msgCount;
  },
  SET_FARM_INFO: (state, { farmInfo }) => {
    localStorage.setItem("farmInfo", JSON.stringify(farmInfo));
    state.farmInfo = farmInfo;
  },
  SET_WEB_MENU: (state, { web_menu }) => {
    if (web_menu) {
      state.web_menu = web_menu;
    }
  },
  SET_FARM_Id: (state, { farmId }) => {
    localStorage.setItem("farmId", farmId);
    state.farmID = farmId;
  },
  SET_FARM_SHOW: (state, { farmShow }) => {
    state.farmShow = farmShow;
  },
  SET_TASK_COUNT: (state, { taskCount }) => {
    state.taskCount = taskCount <= 0 ? 0 : taskCount;
  },

  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
