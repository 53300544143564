<template>
  <div id="app">
    <Home></Home>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
    };
  },
  components: {
    Home: () => import("@/views/HomeView.vue"),
  },
};
</script>
<style lang="less">
// 遮罩
.el-loading-mask.is-fullscreen {
  z-index: 9999 !important;
}
#app {
  .el-dialog {
    box-shadow: none;
  }
  &.column-active {
    display: flex;
  }
  // number类型的input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: center;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #0e2f53;
    color: #fff;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff;
  }
  .el-input__inner,
  .el-textarea__inner {
    &::-webkit-input-placeholder {
      color: #57708d;
    }
  }
  // 未读标记
  .el-badge__content {
    background-color: #d30000;
    border: none;
  }
  .el-input__inner:focus,
  .el-textarea__inner:focus {
    border-color: #3e90e5;
  }
  .el-form-item.is-error .el-input__inner,
  .el-form-item.is-error .el-input__inner:focus,
  .el-form-item.is-error .el-textarea__inner,
  .el-form-item.is-error .el-textarea__inner:focus,
  .el-message-box__input input.invalid,
  .el-message-box__input input.invalid:focus {
    border-color: #f56c6c;
  }
  // 弹窗
  .el-dialog__wrapper {
    z-index: 9998 !important;
  }
}
.el-popover.el-popper,
.el-image-viewer__wrapper,
.el-message-box__wrapper,
.el-select-dropdown.el-popper,
.el-picker-panel.el-date-range-picker.el-popper.has-time {
  z-index: 9999 !important;
}

// header popover弹出层
.user-div {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  img {
    margin: 0 10px;
    vertical-align: middle;
  }
  p {
    position: relative;
    margin-left: 6px;
  }
}
.el-popover.el-popper {
  padding: 0;
  border: none;
  top: 49px !important;
  .popper__arrow {
    border: none;
  }
  .popper__arrow::after {
    display: none;
  }
}
.user-ul {
  width: 100%;
  min-height: 102px;
  background: #0f243d;
  opacity: 0.92;
  display: flex;
  flex-direction: column;
  .user-p {
    height: 33.33%;
    width: 100%;
    padding: 0 8px;
    color: #fff;
    line-height: 33px;
    cursor: pointer;
    text-align: center;
    &:hover {
      opacity: 0.8;
    }
  }
  .out-login {
    color: #ff0000;
  }
}
.analysis-pop {
  .el-dialog {
    background-color: inherit;
  }

  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__headerbtn {
    font-size: 26px;
    top: 64px;
    right: 50px;
    font-weight: bold;
  }
}
.analysis-pop {
  .el-dialog__header {
    width: 860px !important;
    position: relative;
  }
  .el-dialog__headerbtn {
    top: 100px;
    right: 100px;
    font-size: 30px;
    color: #91c3f8;
  }

  .analysis-main {
    width: 860px;
    height: 493px;
    background: url(./assets/image/analyse/pop_bg.png) no-repeat center center;

    background-size: cover;
    padding: 65px 55px;
    overflow: hidden;
    .ware-title {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;

      font-family: FZLTHJW;
      font-weight: normal;
      font-size: 40px;
      color: #91c3f8;
    }

    .analysis-content {
      width: 100%;
      height: 74%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .analysis-item {
        width: 100%;
        .analysis-show-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #fff;
          padding: 20px 30px;
          background-color: rgba(0, 0, 0, 0.2);
          .analysis-item-title {
            width: 20%;
            text-align: center;
            position: relative;
            &::before {
              content: "";
              height: 100%;
              width: 4px;
              border-radius: 4px;
              background-color: #3e90e5;
              position: absolute;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .analysis-item-data {
            text-align: center;
            width: 30%;
            span {
              &:last-of-type {
                font-weight: bold;
              }
            }
          }
          .analysis-item-result {
            text-align: center;
            width: 20%;

            &.normal {
              color: #67c23a;
            }
            &.high {
              color: #f56c6c;
            }
            &.low {
              color: #e6a23c;
            }
          }
        }
      }
    }
  }
}
</style>
