// 全局自定义指令
import Vue from "vue";
// 全局指令
// el-select
Vue.directive("loadMore", {
  bind(el, binding) {
    //el.querySelector 返回与指定的选择器组匹配的元素的后代的第一个元素
    let select_dom = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap"
    );
    select_dom.addEventListener("scroll", function () {
      let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (height) {
        binding.value();
      }
    });
  },
});
// 普通元素
Vue.directive("blockLoadMore", {
  bind(el, binding) {
    el.addEventListener("scroll", function () {
      let height = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (height) {
        binding.value();
      }
    });
  },
});
